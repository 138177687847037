<!--
 * @Author: zqy
 * @Date: 2022-09-05 11:11:27
 * @LastEditTime: 2024-01-16 10:50:16
 * @Description: 
 * @LastEditors: zqy
-->
<template>
  <div class="app-config">
    <header>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="APP配置" name="appConfig"></el-tab-pane>
      </el-tabs>
    </header>
    <article v-if="activeName==='appConfig'" v-loading="loadingFlag">
      <section class="app-wrap">
        <div class='app-content'>
          <h1>基础app</h1>
          <div class="android common-css">
            <el-button @click='appUpdate(true)'>
              点击更新
            </el-button>
            <svg class="iconfont-svg" aria-hidden="true">
              <use xlink:href="#iconanzhuo" />
            </svg>
            <div class="padding-b-t">
              <a v-if="appDetail && appDetail.android" :href="appDetail.android" target="_blank">下载</a>
              <span v-if="appDetail && !appDetail.android" style="color:red">尚未设置</span>
            </div>
            <el-upload
              class="upload-css"
              ref="androidUpload"
              drag
              :headers="{bimcctoken:token}"
              :action="baseUrl+'api/app/importAndroid'"
              accept=".apk"
              :on-success="uploadSuccess"
              multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或 <em>点击上传</em></div>
            </el-upload>
          </div>
          <div class="ios common-css">
            <svg class="iconfont-svg" aria-hidden="true">
              <use xlink:href="#iconiOS" />
            </svg>
            <div class="padding-b-t">
              <template v-if="appDetail">
                <span>兑换码总量 :</span>
                {{appDetail.ios_all}}
                <span>| 剩余兑换码 :</span>
                {{appDetail.ios_can}}
              </template>
            </div>
            <el-upload
              class="upload-css"
              ref="iosUpload"
              drag
              :headers="{bimcctoken:token}"
              :action="baseUrl+'api/app/importIosCode'"
              accept=".xls, .xlsx"
              :on-success="uploadSuccess"
              multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或 <em>点击上传</em></div>
            </el-upload>
          </div>
        </div>

        <div class='app-content' style='margin-left:8px;'>
          <h1>水印相机</h1>
          <div class="android common-css">
            <el-button @click='appUpdate(false)'>
              点击更新
            </el-button>
            <svg class="iconfont-svg" aria-hidden="true">
              <use xlink:href="#iconanzhuo" />
            </svg>
            <div class="padding-b-t">
              <a v-if="appDetail && appDetail.android" :href="appDetail.android" target="_blank">下载</a>
              <span v-if="appDetail && !appDetail.android" style="color:red">尚未设置</span>
            </div>
            <el-upload
              class="upload-css"
              ref="androidUpload"
              drag
              :headers="{bimcctoken:token}"
              :action="baseUrl+'api/app/watermark/importAndroid'"
              accept=".apk"
              :on-success="uploadSuccess"
              multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或 <em>点击上传</em></div>
            </el-upload>

          </div>
          <div class="ios common-css">
            <svg class="iconfont-svg" aria-hidden="true">
              <use xlink:href="#iconiOS" />
            </svg>
            <div class="padding-b-t">
              <template v-if="appDetail">
                <span>兑换码总量 :</span>
                {{appDetail.ios_all}}
                <span>| 剩余兑换码 :</span>
                {{appDetail.ios_can}}
              </template>
            </div>
            <el-upload
              class="upload-css"
              ref="iosUpload"
              drag
              :headers="{bimcctoken:token}"
              :action="baseUrl+'api/app/watermark/importIosCode'"
              accept=".xls, .xlsx"
              :on-success="uploadSuccess"
              multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或 <em>点击上传</em></div>
            </el-upload>
          </div>
        </div>

      </section>
    </article>
    <el-dialog :title="isBaseApp ? '上传apk':'上传水印相机apk'" width="50%" :visible.sync="dialogCamera" :modal='false'>
      <el-form>
        <el-form-item label="appid(项目路由减去https)">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>
        <el-form-item label="版本号">
          <el-input v-model="form.version"></el-input>
        </el-form-item>
        <el-form-item label="更新简介">
          <el-input v-model="form.note"></el-input>
        </el-form-item>
        <el-upload
          class="upload-css"
          ref="androidUpload"
          drag
          :headers="{bimcctoken:token}"
          :action="`${baseUrl}api/mapi?__method_name__=file&token=${token}`"
          accept=".apk"
          :on-success="uploadSuccessCamera"
          multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或 <em>点击上传</em></div>
        </el-upload>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCamera = false">取 消</el-button>
        <el-button type="primary" @click="cameraSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Tabs, TabPane } from 'element-ui';
import { formRequest } from "@/apis/data/form";
import { getToken } from '@/utils/tools';
export default {
  components:{
    'el-tabs':Tabs,
    'el-tab-pane':TabPane
  },
  data(){
    return{
      activeName:'appConfig',
      appDetail: null,
      baseUrl: process.env.VUE_APP_BASE_URL || 'https://dwh_api.bimcc.net/',
      token: getToken(),
      loadingFlag: false,
      dialogCamera:false,
      form:{
        appid:'',
        version:'',
        note:'',
        file:null,
      },
      isBaseApp: false
    }
  },
  methods:{
    appUpdate(flag) {
      this.isBaseApp = flag;
      this.dialogCamera = true;
    },
    cameraSubmit(){
      if(!this.form.file){
        this.$message({
          showClose: true,
          message: '请上传apk',
          type: 'error'
        });
        return; 
      }
      if (!this.isBaseApp) {
        this.form.appid = 'com.example.watermark_camera_' + this.form.appid;
      }
      formRequest('post','/api/app/appRenewImport',this.form).then(res=>{
        console.log(res);
        if(res.data.code===200){
          this.$message({
            showClose: true,
            message: '上传成功！',
            type: 'success'
          });
          this.form = {
            appid:'',
            version:'',
            note:'',
            file:null,
          }
          this.dialogCamera = false
        }
      })
    },
    uploadSuccessCamera(res){
      console.log(res);
      if(res.code === 200){
        this.form.file = [res.data]
      }
    },
    uploadSuccess(res){
      this.$refs.androidUpload.clearFiles();
      this.$refs.iosUpload.clearFiles();
      if(+res.code === 200){
        this.getStatus();
      }else{
        this.$message({
          showClose: true,
          message: '上传失败！',
          type: 'error'
        });
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getStatus(){
      this.loadingFlag = true;
      formRequest('post','/api/app/getStatus',{}).then(res => {
        this.loadingFlag = false;
        if (res.data.code === 200) {
          const data = res.data.data;
          this.appDetail = data;
        } else {
          this.$message({
            showClose: true,
            message: '获取失败！',
            type: 'error'
          });
        }
      }).catch(err=>{
        console.log(err,'err');
        this.loadingFlag = false;
        this.$message({
          showClose: true,
          message: '获取失败！',
          type: 'error'
        });
      })
    },
    getCameraStatus(){
      this.loadingFlag = true;
      formRequest('post','/api/app/watermark/getStatus',{}).then(res => {
        this.loadingFlag = false;
        if (res.data.code === 200) {
          const data = res.data.data;
          this.appDetail = data;
        } else {
          this.$message({
            showClose: true,
            message: '获取失败！',
            type: 'error'
          });
        }
      }).catch(err=>{
        console.log(err,'err');
        this.loadingFlag = false;
        this.$message({
          showClose: true,
          message: '获取失败！',
          type: 'error'
        });
      })
    }
  },

  mounted(){
    this.getStatus();
  }
}
</script>
<style lang="less">
.app-config{
  .el-upload-dragger{
    width: 260px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .el-icon-upload{
    margin: unset !important;;
  }
}
</style>
<style lang="less" scoped>
.app-config{
  height: 100%;
  width: 100%;
  padding: 16px;
  .app-wrap{
    display: flex;
    padding-top: 20px;
    .app-content{
      display:flex;
      border: 1px solid #f2f3f5;
      padding:20px;
      border-radius:8px;
    }
    .common-css{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
    .android{

    }
    .ios{

    }
  }
  .padding-b-t{
    padding: 20px 0;
  }
  .upload-css{

  }
}
</style>